<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Reportes de pago</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedBank"
          :items="banks"
          placeholder="Escoge el banco"
          outlined
          style="width: 90%"
          item-text="name"
          item-disabled="disabled"
          item-value="value"
          rounded
          class="mt-5 ml-5"
        />
      </v-col>

      <v-col cols="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            :loading="loading"
            :disabled="!selectedBank"
            @click="newReport"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Generar reporte
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="reports"
          :items-per-page="15"
          :loading="loading"
          :search="$store.state.search"
          :sort-desc="true"
          item-key=".key"
          sort-by="dateCreated"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.dateCreated`]="{ item }">
            {{ item.dateCreated | filterDate }}
          </template>

          <template v-slot:[`item.dateStart`]="{ item }">
            {{ item.dateStart | filterDate }}
          </template>

          <template v-slot:[`item.dateEnd`]="{ item }">
            {{ item.dateEnd | filterDate }}
          </template>

          <!-- Duplicate code JoseV. -->
          <!-- <template v-slot:[`item.dateEnd`]="{ item }">
                         {{item.dateEnd | filterDate}}
                    </template> -->

          <template v-slot:[`item.options`]="{ item }">
            <v-btn
              @click="downloadItem(item.url.xlsxFile, item.xlsxName)"
              small
              color="primary"
              >Descargar .xlsx</v-btn
            >
            <v-btn
              @click="downloadItem(item.url.prnFile, item.prnName)"
              small
              color="primary"
              class="ml-3"
              >Descargar .prn</v-btn
            >
          </template>

          <template v-slot:[`item.sent`]="{ item }">
            <v-chip
              v-if="item.sent"
              class="ma-2"
              color="green"
              text-color="white"
            >
              Enviado
            </v-chip>

            <v-chip v-else class="ma-2"> No enviado </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-if="!loading"
      persistent
      v-model="generateReport"
      max-width="650px"
    >
      <new-report
        :key="randomKey"
        @success="success"
        @cancel="generateReport = false"
      ></new-report>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import axios from "axios";
import newReport from "./new-report";

export default {
  name: "payment-reports",
  components: {
    lottie: Lottie,
    newReport,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      snackbarText: "",
      reports: [],
      selectedBank: "",
      generateReport: false,
      randomKey: 0,
      banks: [
        { value: 11, name: "BAC Honduras", disabled: false },
        { value: 1, name: "Banco Central de Honduras", disabled: true },
        { value: 2, name: "Banco Atlántida", disabled: true },
        { value: 3, name: "Banco Ficohsa CB", disabled: true },
        { value: 4, name: "Banco de Trabajadores", disabled: true },
        { value: 5, name: "Banco de Occidente", disabled: true },
        { value: 6, name: "Banco de Honduras", disabled: true },
        {
          value: 7,
          name: "Banco Hondureño del Cafe (Banhcafe)",
          disabled: true,
        },
        { value: 8, name: "Banco del Pais (Banpais)", disabled: true },
        { value: 9, name: "Banco Lafise", disabled: true },
        { value: 10, name: "Banco Ficensa", disabled: true },
        { value: 12, name: "Banco Promerica", disabled: true },
        { value: 13, name: "Banco Ficohsa", disabled: true },
        { value: 14, name: "Banco Davivienda", disabled: true },
        { value: 15, name: "BanRural", disabled: true },
        { value: 16, name: "Banco Azteca", disabled: true },
        {
          value: 17,
          name: "Banco Nacional de Desarrollo Agricola( Banadesa)",
          disabled: true,
        },
        { value: 18, name: "Financiera CrediQ", disabled: true },
        { value: 19, name: "Banco Popular", disabled: true },
      ],
      headers: [
        {
          text: "Código de reporte",
          value: "paymentNumber",
          // width: '150px'
        },
        {
          text: "Descripción",
          value: "description",
          width: '250px'
        },
        {
          text: "Banco",
          value: "bank.name",
          width: "160px",
        },

        {
          text: "Fecha inicio reporte",
          value: "dateStart",
          // width: "220px",
        },
        {
          text: "Fecha final reporte",
          value: "dateEnd",
          // width: "220px",
        },
        {
          text: "Creado",
          value: "dateCreated",
          // width: "220px",
        },
        // {
        //   text: "Enviado",
        //   value: "sent",
        // },
        {
          value: "options",
          width: "350px",
          align: "end",
        },
      ],
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY");
    },
  },
  watch: {
    selectedBank(bankId) {
      this.loading = true;
      this.$binding(
        "reports",
        db.collection("providerPayments").where("bank.id", "==", bankId)
      ).then((reports) => {
        this.loading = false;
      });
    },
  },
  methods: {
    success() {
      this.generateReport = false;
      this.snackbarText = "Reporte de pago generado exitosamente.";
      this.snackbar = true;
    },
    downloadItem(url, name) {
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      });
    },
    newReport() {
      this.randomKey = Math.random();
      this.generateReport = true;
    },
  },
  mounted() {
    this.$store.state.searchTerm = "reporte";
    this.loading = false;
  },
};
</script>
