<template>
  <div>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Generar reporte de pago</span>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="pa-3">
          <v-snackbar top :timeout="3000" v-model="snackbar">
            {{ snackbarText }}
          </v-snackbar>
          <v-overlay class="loading-center" :value="saving">
            <lottie
              :options="defaultOptions"
              :height="300"
              :width="400"
              v-on:animCreated="handleAnimation"
            />
          </v-overlay>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model.trim="code"
                placeholder="Código del reporte"
                rounded
                hint="Dejar vacio para que el sistema asigne un código automático."
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                rounded
                v-model="description"
                rows="3"
                outlined
                label="Descripción del reportes"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <p>Fecha inicial:</p>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <input
                    class="control-input"
                    v-on="on"
                    type="text"
                    placeholder="Fecha inicia"
                    v-model="date[0]"
                  />
                </template>
                <v-date-picker v-model="date[0]" @input="menu1 = false">
                  <!-- <v-row no-gutters style="flex-wrap: nowrap">
                    <v-btn
                      class="flex-grow-1 flex-shrink-0"
                      large
                      rounded
                      outlined
                      color="primary"
                      @click="menu1 = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      class="flex-grow-1 flex-shrink-0"
                      large
                      rounded
                      color="primary"
                      @click="$refs.menu1.save(date[0])"
                    >
                      Aceptar
                    </v-btn>
                  </v-row> -->
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="6">
              <p>Fecha final:</p>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <input
                    class="control-input"
                    v-on="on"
                    type="text"
                    placeholder="Fecha final"
                    v-model="date[1]"
                  />
                </template>
                <v-date-picker @input="menu2 = false" v-model="date[1]">
                  <!-- <v-row no-gutters style="flex-wrap: nowrap">
                    <v-btn
                      class="flex-grow-1 flex-shrink-0"
                      large
                      rounded
                      outlined
                      color="primary"
                      @click="menu2 = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      class="flex-grow-1 flex-shrink-0"
                      large
                      rounded
                      color="primary"
                      @click="$refs.menu2.save(date[1])"
                    >
                      Aceptar
                    </v-btn>
                  </v-row> -->
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 mt-8">
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="6">
              <v-row align="center" justify="start">
                <!-- <v-switch v-model="coupon.active" label="Cupón activo"></v-switch> -->
              </v-row>
            </v-col>

            <v-col cols="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="generateReport"
                  class="save-btn mt-4"
                  color="primary"
                >
                  Generar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import axios from "axios";

export default {
  name: "new-report",
  components: {
    lottie: Lottie,
  },
  mixins: [lottieSettings],
  data() {
    return {
      saving: false,
      snackbarText: "",
      snackbar: false,
      menu1: "",
      menu2: "",
      date: [],
      description: "",
      code: "",
    };
  },
  computed: {
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
  },
  methods: {
    async generateReport() {
      if (!this.date[0] || !this.date[1]) {
        this.snackbarText = "Debe completar las fechas";
        this.snackbar = true;
        return;
      }

      if (this.date[0] > this.date[1]) {
        this.snackbarText =
          "La fecha final debe ser mayor que la fecha inicial";
        this.snackbar = true;
        return;
      }

      this.saving = true;
      let data = {
        dateStart: this.date[0],
        dateEnd: this.date[1],
        code: this.code ? this.code : "",
        description: this.description ? this.description : "",
      };

      var httpGeneratePaymentReportV2 = fb
        .functions()
        .httpsCallable("httpGeneratePaymentReportV2");
      httpGeneratePaymentReportV2(data)
        .then((result) => {
          this.saving = false;
          this.$emit("success");
        })
        .catch((err) => {
          this.saving = false;
          this.snackbar = true;
          this.snackbarText = `Ocurrió un error inesperado, inténtelo nuevamente.`;
        });
    },
    generateTimestamp() {
      return new Promise((resolve, reject) => {
        let date = this.date[0].split("-");
        this.coupon.redeemableFrom = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt("07"),
            parseInt("00"),
            parseInt("00")
          )
        );
        date = this.date[1].split("-");
        this.coupon.redeemableUntil = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt("19"),
            parseInt("00"),
            parseInt("00")
          )
        );
        resolve(true);
      });
    },
  },
};
</script>
